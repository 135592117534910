<template>
  <div class="c-my-calendar">
    <n-loader :loading="$var('load')" />

    <div class="top">
      <div class="month">
        <div class="month-prev btn" @click="decrease"><img :src="left" alt=""></div>
        <div class="month-title">{{ months[month] }} {{ year }}</div>
        <div class="month-next btn" @click="increase"><img :src="right" alt=""></div>
      </div>

      <div class="hint">
        <div v-for="(item, index) in types" :key="index" class="hint-item">
          <div class="circle" :style="'background-color:'+item.color+'; background-image:'+item.color+';'"></div>
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="wrapper flex">
      <div class="left">
        <div class="calendar">
          <div class="calendar-title">
            <div v-for="item in day" :key="item.id" class="day">{{ item }}</div>
          </div>
          <div class="wrap">
            <div v-for="(week, index) in calendar()" :key="index" class="week">
              <div
                class="calendar-item"
                :class="{'inactive': !week[1]?.index, 'selected': (compareDate(week[1]?.index) || inSelected(week[1]?.index)),
                         [getClass(workDays[week[1]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[1]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[1]?.index))"
                @click.stop="chooseDay(week[1]?.index)"
                @dblclick="changeWorkDay(!workDays[week[1].numY])">
                <div class="top">{{ week[1]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[1]?.numY])" class="bottom">
                  {{ getTime(workDays[week[1]?.numY]) +' - '+ getTime(workDays[week[1]?.numY], 'end') }}
                </div>
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[2]?.index, 'selected': (compareDate(week[2]?.index) || inSelected(week[2]?.index)),
                         [getClass(workDays[week[2]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[2]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[2]?.index))"
                @click.stop="chooseDay(week[2]?.index)"
                @dblclick="changeWorkDay(!workDays[week[2].numY])">
                <div class="top">{{ week[2]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[2]?.numY])" class="bottom">
                  {{ getTime(workDays[week[2]?.numY]) +' - '+ getTime(workDays[week[2]?.numY], 'end') }}
                </div>
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[3]?.index, 'selected': (compareDate(week[3]?.index) || inSelected(week[3]?.index)),
                         [getClass(workDays[week[3]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[3]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[3]?.index))"
                @click.stop="chooseDay(week[3]?.index)"
                @dblclick="changeWorkDay(!workDays[week[3].numY])">
                <div class="top">{{ week[3]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[3]?.numY])" class="bottom">
                  {{ getTime(workDays[week[3]?.numY]) +' - '+ getTime(workDays[week[3]?.numY], 'end') }}
                </div>
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[4]?.index, 'selected':(compareDate(week[4]?.index) || inSelected(week[4]?.index)),
                         [getClass(workDays[week[4]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[4]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[4]?.index))"
                @click.stop="chooseDay(week[4]?.index)"
                @dblclick="changeWorkDay(!workDays[week[4].numY])">
                <div class="top">{{ week[4]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[4]?.numY])" class="bottom">
                  {{ getTime(workDays[week[4]?.numY]) +' - '+ getTime(workDays[week[4]?.numY], 'end') }}
                </div>
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[5]?.index, 'selected': (compareDate(week[5]?.index) || inSelected(week[5]?.index)),
                         [getClass(workDays[week[5]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[5]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[5]?.index))"
                @click.stop="chooseDay(week[5]?.index)"
                @dblclick="changeWorkDay(!workDays[week[5].numY])">
                <div class="top">{{ week[5]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[5]?.numY])" class="bottom">
                  {{ getTime(workDays[week[5]?.numY]) +' - '+ getTime(workDays[week[5]?.numY], 'end') }}
                </div>
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[6]?.index, 'selected': (compareDate(week[6]?.index) || inSelected(week[6]?.index)),
                         [getClass(workDays[week[6]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[6]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[6]?.index))"
                @click.stop="chooseDay(week[6]?.index)"
                @dblclick="changeWorkDay(!workDays[week[6].numY])">
                <div class="top">{{ week[6]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[6]?.numY])" class="bottom">
                  {{ getTime(workDays[week[6]?.numY]) +' - '+ getTime(workDays[week[6]?.numY], 'end') }}
                </div>
              </div>
              <div
                class="calendar-item"
                :class="{'inactive': !week[0]?.index, 'selected': (compareDate(week[0]?.index) || inSelected(week[0]?.index)),
                         [getClass(workDays[week[0]?.numY])]: true, }"
                :style="'background-color:'+getColor(workDays[week[0]?.numY])+';'"
                @click.ctrl.stop="ctrlAdd(getDate(week[0]?.index))"
                @click.stop="chooseDay(week[0]?.index)"
                @dblclick="changeWorkDay(!workDays[week[0].numY])">
                <div class="top">{{ week[0]?.index }}</div>
                <div v-show="isShowWorkHours(workDays[week[0]?.numY])" class="bottom">
                  {{ getTime(workDays[week[0]?.numY]) +' - '+ getTime(workDays[week[0]?.numY], 'end') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <n-button v-if="!text" @click="save">Сохранить</n-button>
      </div>

      <div class="right">
        <div class="form-wrap">
          <div class="text">Дата: {{ dateForView.date }}</div>
          <div class="text">Тип посещения: </div>
          <div>
            <div class="custom-checkbox" @click="changeWorkDayType(workDays[getNumberOfDay(cDate)]?.dayType === 'free' ? 'normal' : 'free')">
              <input type="checkbox" :checked="workDays[getNumberOfDay(cDate)]?.dayType === 'free'">
              <div class="checkbox"></div>
              <span class="text">Свободный график</span>
            </div>
            <div
              class="custom-checkbox" 
              @click="changeWorkDayType(workDays[getNumberOfDay(cDate)]?.dayType === 'transition' ? 'normal' : 'transition')">
              <input type="checkbox" :checked="workDays[getNumberOfDay(cDate)]?.dayType === 'transition'">
              <div class="checkbox"></div>
              <span class="text">24 часовая смена</span>
            </div>
          </div>
          <div>
            <div v-if="workDays[getNumberOfDay(cDate)]?.dayType === 'free'" class="custom-checkbox">
              <div class="time-selector-place">
                План часов на день:
                <div class="time-selector">
                  <n-select
                    small
                    name="inputdisabled" :data="hours" v-bind="$form.input('planH', 'select')"
                    class="time-select" @select="changeWorkDay(selectedItem?.type)" />
                  :<n-select
                    small
                    name="inputdisabled2" :data="minutes" v-bind="$form.input('planM', 'select')"
                    class="time-select" @select="changeWorkDay(selectedItem?.type)" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="Object.keys(selectedDates).length === 0 && !isCompany" class="text">Рабочее время</div>
          <div v-if="Object.keys(selectedDates).length === 0 && !isCompany" class="selectors">
            <div class="time-selector-place">
              Время начала рабочего дня:
              <div class="time-selector">
                <n-select
                  small
                  name="inputdisabled" :data="hours" v-bind="$form.input('startHours', 'select')"
                  class="time-select" @select="changeWorkDay(selectedItem?.type)" />
                :<n-select
                  small
                  name="inputdisabled2" :data="minutes" v-bind="$form.input('startMinutes', 'select')"
                  class="time-select" @select="changeWorkDay(selectedItem?.type)" />
              </div>
            </div>
            <div class="time-selector-place">
              Время окончания рабочего дня:
              <div class="time-selector">
                <n-select
                  small
                  name="inputdisabled" :data="hours" v-bind="$form.input('endHours', 'select')"
                  class="time-select" @select="changeWorkDay(selectedItem?.type)" />
                :<n-select
                  small
                  name="inputdisabled2" :data="minutes" v-bind="$form.input('endMinutes', 'select')"
                  class="time-select" @select="changeWorkDay(selectedItem?.type)" />
              </div>
            </div>
          </div>
          <div class="text">Тип: </div>
          <div>
            <div v-for="(item, index) in types" :key="index" class="custom-checkbox">
              <input type="checkbox" :checked="dateForView.value === item.value" @click="changeWorkDay(item.value, true)">
              <div class="checkbox" :style="dateForView.value === item.value ? 'background:'+item.color+';': ''"></div>
              <span class="text">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import left from 'assets/arrow-left.svg'
import right from 'assets/arrow-right.svg'

export default {
  name: 'CMyCalendar',
  props: {
    workdays: {
      type: [ Object, Array, ],
      required: true,
    },
    text: {
      type: Boolean,
      default: false,
    },
    dbclick: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      required: true,
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
    isUser: {
      type: Boolean,
      default: true,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      left,
      right,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      selectedDates: {},
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      dateForView: '',
      day: [],
      cDate: new Date,
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
      months: [],
      dlast: new Date(this.year, this.month + 1, 0).getDate(),
      isOpen: false,
      workDays: this.workdays,
      ctrlPressed: false,
      hours: [],
      minutes: [],
      startMinutes: 0,
      endMinutes: 0,
      planM: null,
      planH: null,
      startHours: 0,
      endHours: 0,
    }
  },
  computed: {
    selectedHours() {
      const selected = {}
      const item = this.workDays[this.getNumberOfDay(this.cDate)]

      selected.startHours = $n.filter(this.hours, (e) => {
        if (String(e.value) === String(item?.startHours)) {
          return e
        }
      })[0]
      selected.startMinutes = $n.filter(this.minutes, (e) => {
        if (String(e.value) === String(item?.startMinutes)) {
          return e
        }
      })[0]
      selected.endHours = $n.filter(this.hours, (e) => {
        if (String(e.value) === String(item?.endHours)) {
          return e
        }
      })[0]
      selected.endMinutes = $n.filter(this.minutes, (e) => {
        if (String(e.value) === String(item?.endMinutes)) {
          return e
        }
      })[0]
      selected.planM = $n.filter(this.minutes, (e) => {
        if (String(e.value) === String(item?.planM)) {
          return e
        }
      })[0]
      selected.planH = $n.filter(this.minutes, (e) => {
        if (String(e.value) === String(item?.planH)) {
          return e
        }
      })[0]

      return selected
    },
    selectedItem() {
      return this.workDays[this.getNumberOfDay(this.cDate)]
    },
  },
  watch: {
    selectedHours() {
      this.$form.init({
        startMinutes: this.selectedHours?.startMinutes,
        endMinutes: this.selectedHours?.endMinutes,
        startHours: this.selectedHours?.startHours,
        endHours: this.selectedHours?.endHours,
        planM: this.selectedHours?.planM,
        planH: this.selectedHours?.planH,
      })
    },
    workdays() {
      this.workDays = this.workdays
    },
    cDate() {
      this.updateView()
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  },
  created() {
    this.generateHours()
    this.init()
  },
  methods: {
    getTime(item, timeType = 'start') {
      if (item) {
        if (timeType === 'start') {
          return item.startHours + ':' + item.startMinutes
        } else {
          return item.endHours + ':' + item.endMinutes
        }
      }
      return ''
    },
    generateHours() {
      this.hours = Array.from({ length: 24, }, (_, i) => ({
        value: Number(i) > 9? i : '0'+(i),
        title: Number(i) > 9? i : '0'+(i),
      }))
      this.minutes = Array.from({ length: 60, }, (_, i) => ({
        value: Number(i ) > 9?i:'0'+(i),
        title: Number(i) > 9?i:'0'+(i),
      }))
    },
    isShowWorkHours(item) {
      return [ 'was', 'wasnt', 'late', 'work', '', 'none', '1', 'wasInWeekend', ].includes(item?.type) && !this.isCompany
    },
    handleKeyDown(event) {
      if (event.ctrlKey) {
        this.ctrlPressed = true
      }
    },
    handleKeyUp(event) {
      if (!event.ctrlKey) {
        this.ctrlPressed = false
      }
    },
    ctrlAdd(date) {
      this.cDate = null
      if (this.selectedDates[$app.date.format(date, 'date', true)]) {
        const obj = { ...this.selectedDates, }
        delete obj[$app.date.format(date, 'date', true)]
        this.selectedDates = obj
      } else {
        this.selectedDates = {
          ...this.selectedDates,
          [$app.date.format(date, 'date', true)]: date,
        }
      }
    },
    inSelected(day) {
      if (day) {
        const date = this.getDate(day)
        return !!this.selectedDates[$app.date.format(date, 'date', true)]
      }
    },
    getColor(value) {
      if (this.isUser) {
        value = value?.type
      }
      const type = $n.filter(this.types, (e) => {
        if (e.value === value) {
          return e
        }
      })
      return type[0]?.color || '#ffffff'
    },
    getClass(value) {
      if (this.isUser) {
        value = value?.type
      }
      const type = $n.filter(this.types, (e) => {
        if (e.value === value) {
          return e
        }
      })
      return type[0]?.class || ''
    },
    save() {
      let workDays = {}
      if (this.isUser) {
        $n.each(this.workDays, (e, i) => {
          if (i > 0) {
            if (Number(i) <= 366) {
              workDays = {
                ...workDays,
                [Number(i)]: e,
              }
            }
          }
        })
        this.$emit('update:workdays', workDays)
      } else {
        this.$emit('update:workdays', this.workDays)
      }
      this.$emit('ready')
    },
    changeWorkDay(value) {
      if (Object.keys(this.selectedDates).length > 0) {
        $n.each(this.selectedDates, (e) => {
          if (this.isUser) {
            this.workDays = {
              ...this.workDays,
              [this.getNumberOfDay(e)]: {
                ...this.workDays[this.getNumberOfDay(e)],
                type: value,
                startMinutes: String(this.$form.get('startMinutes.value'))|| '00',
                startHours: String(this.$form.get('startHours.value')) || '00',
                endMinutes: String(this.$form.get('endMinutes.value')) || '00',
                endHours: String(this.$form.get('endHours.value')) || '00',
                planM: String(this.$form.get('planM.value')) || null,
                planH: String(this.$form.get('planH.value')) || null,
              },
            }
          }
        })
      } else {
        if (this.isUser) {
          this.workDays = {
            ...this.workDays,
            [this.getNumberOfDay(this.cDate)]: {
              ...this.workDays[this.getNumberOfDay(this.cDate)],
              type: value,
              startMinutes: String(this.$form.get('startMinutes.value'))|| '00',
              startHours: String(this.$form.get('startHours.value')) || '00',
              endMinutes: String(this.$form.get('endMinutes.value')) || '00',
              endHours: String(this.$form.get('endHours.value')) || '00',
              planM: String(this.$form.get('planM.value')) || null,
              planH: String(this.$form.get('planH.value')) || null,
            },
          }
        }
      }
      this.updateView()
    },
    changeWorkDayType(value) {
      if (Object.keys(this.selectedDates).length > 0) {
        $n.each(this.selectedDates, (e) => {
          if (this.isUser) {
            this.workDays = {
              ...this.workDays,
              [this.getNumberOfDay(e)]: {
                ...this.workDays[this.getNumberOfDay(e)],
                dayType: value,
              },
            }
          }
        })
      } else {
        if (this.isUser) {
          this.workDays = {
            ...this.workDays,
            [this.getNumberOfDay(this.cDate)]: {
              ...this.workDays[this.getNumberOfDay(this.cDate)],
              dayType: value,
            },
          }
        }
      }
      this.updateView()
    },
    init() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    updateView() {
      if (this.cDate) {
        this.dateForView = {
          date: (this.cDate.getDate() <= 9 ? '0'+this.cDate.getDate(): this.cDate.getDate()) +
              ' '+ this.months[this.month].substr(0, 3) +'. '+ this.year,
          value: this.isUser ? this.workDays[this.getNumberOfDay(this.cDate)]?.type : this.workDays[this.getNumberOfDay(this.cDate)-1],
        }
      }
    },
    chooseDay(day) {
      if (!this.ctrlPressed) {
        this.selectedDates = {}
        if (day) {
          this.cDate = new Date(this.year, this.month, day)
          this.isOpen = false
        }
      }
    },
    getDate(day) {
      if (day) {
        return new Date(this.year, this.month, day)
      }
    },
    compareDate(day, sign = '=') {
      if (this.cDate) {
        if (sign === '=') {
          return $app.date.format(this.cDate, 'date', true) === $app.date.format(new Date(this.year, this.month, day), 'date', true)
        } else {
          if (sign === '>=') {
            return $app.date.format(this.cDate, 'date', true) >= $app.date.format(new Date(this.year, this.month, day), 'date', true)
          } else {
            return $app.date.format(this.cDate, 'date', true) <= $app.date.format(new Date(this.year, this.month, day), 'date', true)
          }
        }
      }
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    calendar: function() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.year, this.month + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        if (!this.isUser) {
          days = {
            ...days,
            [new Date(this.year, this.month, i).getDay()]: { index: i, numY: Number(this.getNumberOfDay(new Date(this.year, this.month, i)) - 1), },
          }
        } else {
          days = {
            ...days,
            [new Date(this.year, this.month, i).getDay()]: { index: i, numY: Number(this.getNumberOfDay(new Date(this.year, this.month, i))), },
          }
        }
        if (new Date(this.year, this.month, i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week+=1
          days = {}
        }
      }
      if (Object.keys(days).length !== 0) {
        weeks = {
          ...weeks,
          [week]: days,
        }
      }

      return weeks
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
        this.$emit('update:year', this.year)
      }
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
        this.$emit('update:year', this.year)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-my-calendar {

  background-color: #FFFFFF;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 30px;
  .wasInWeekend {
    background-image: linear-gradient(315deg, #64C038 45%, #CFCECE 55%);
  }
  .time-selector {
    display: flex;
    align-items: center;
    justify-content: start;
    ::v-deep {
      .n-select {
        .n-icon {
          display: none;
        }
        .n-text {
          text-align: center;
        }
        &:first-child {
          margin-right: 5px;
        }
        &:last-child {
          margin-left: 5px;
        }
        width: 60px;
        .n-content {
          padding: 0;
        }
        .n-icon {
          margin: 0 !important;
        }
        .n-popup-content {
          width: 60px;
          .n-dropdown-item {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .n-item {
            text-align: center;
          }
        }
        .n-wrapper {
          padding: 0 5px;
          width: 60px;
        }
        .n-dropdown {
          width: 60px;
        }
      }
    }
  }

  .time-selector-place {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .time-selector {
      margin-left: 10px;
    }
  }

  .n-tabs {
    justify-content: center;
    margin-top: 20px;

    &::v-deep {
      & > .n-tab {
        margin: 0 14px;
        padding: 0;
        color: #000000;
        cursor: pointer;
        flex-shrink: 0;
        min-width: 250px;
        min-height: 40px;
        font-size: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--primary);
        border-radius: 24px;
        background-color: #FFFFFF;

        &.active {
          background: var(--primary);
          border: 3px solid var(--primary);
          color: #fff;
        }
      }
    }
  }

  .wrapper {
    min-height: 400px;

    &.flex {
      display: flex;
      .left {
        .n-button {
          margin-top: 20px;
          min-width: 240px;
          color: #FFFFFF;
          border: 3px solid var(--primary);
          border-radius: 24px;
          background-color: var(--primary);
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .n-button {
          margin-top: 20px;
          min-width: 240px;
          color: #FFFFFF;
          border: 3px solid var(--primary);
          border-radius: 24px;
          background-color: var(--primary);
        }
      }
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    margin-bottom: 18px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & + .title {
      margin-top: 30px;
    }

    &-title {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      width: 180px;
    }

    &-text {
      background: #FFFFFF;
      border: 1px solid #8A8888;
      border-radius: 2px;
      width: 490px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      height: 30px;
      position: relative;
    }
  }

  .change-color {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &::after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--primary);
    }
  }

  .n-input {
    width: 490px;
  }

  .logo {
    width: 100px;

    img {
      max-width: 100%;
    }
  }


  .buttons {
    margin: 40px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;


    .n-button {
      min-width: 240px;
      color: #FFFFFF;
      border: 3px solid var(--primary);
      border-radius: 24px;
      background-color: var(--primary);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .hint {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    &-item {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      margin-right: 30px;
      width: 33.3333333%;

      &:last-child {
        margin-right: 0;
      }
    }

    .circle {
      margin-right: 8px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #FFF;
      border: 1px solid #CFCECE;

      &.green {
        background: #64C038;
        border: 1px solid #64C038;
      }
    }
  }
  .week {
    display: flex;
    align-items: center;
  }

  .calendar {
    width: 700px;

    &-title {
      display: flex;
      margin-bottom: 8px;

      .day {
        padding-left: 10px;
        width: 100px;
        font-weight: 400;
        font-size: 16px;
        color: #777676;
      }
    }

    &-item {
      padding: 5px;
      width: 100px;
      height: 82px;
      border: 1px solid #777676;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .bottom {
        display: flex;
        align-items: center;
        justify-content: right;
        font-size: 10px;
      }
      &.vac {
        background-color: #64C038;
      }
      &.selected {
        border: 3px solid var(--primary);
      }
      &.inactive {
        background-color: #d8d8d8 !important;
      }
    }
  }

  .month {
    margin-bottom: 20px;
    background: var(--primary);
    border-radius: 8px;
    width: 200px;
    height: 45px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #FFF;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .form-wrap {
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 8px rgb(0 0 0 / 15%);
    border-radius: 12px;
    margin-top: 32px;
    margin-left: 40px;
    & > .text {
      margin-bottom: 20px;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      color: #000;
    }
  }

  .custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    input[type="checkbox"] {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;

      &:checked {
        & + .checkbox {
          background: #64C038;
        }
      }
    }

    .checkbox {
      margin-right: 15px;
      width: 20px;
      flex-shrink: 0;
      height: 20px;
      background: #FFF;
      border-radius: 50%;
      border: 1px solid #CFCECE;
      transition: all .3s ease-in-out;
    }
  }
}
</style>
