<template>
  <div class="c-show-visits-on-map" @click="close">
    <n-loader :loading="$var('load')" />
    <div class="form" @click.stop>
      <div class="title">Просмотр местоположений посещения</div>

      <div class="date-place">
        <div class="title">Выберите дату</div>
        <c-date-picker :date.sync="date" />
      </div>
      <div id="map"></div>

      <div class="buttons">
        <n-button class="cancel" @click="close">Закрыть</n-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CShowVisitsOnMap',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      date: new Date(),
      visits: [],
    }
  },
  watch: {
    date() {
      this.getVisits()
    },
  },
  mounted() {
    ymaps.ready(this.initMap)
    this.getVisits()
  },
  methods: {
    getVisits() {
      this.$var('load', true)
      $api.visits.getForMap(this.userId, { date: this.date, }).then((response) => {
        this.visits = response.data.content
        this.showPoints()
      }).then(() => {
        this.$var('load', false)
      })
    },
    close() {
      this.$emit('update:show', false)
    },
    showPoints() {
      this.map.geoObjects.removeAll()
      let lastVisit = {}
      $n.each(this.visits, (e) => {
        if (e.lat) {
          const placemark = new ymaps.Placemark(
            [ e.lat, e.lon, ],
            {
              balloonContent: `Посещение было отмечено: ${$app.date.format(e.createdAt, 'datetime')}`, // Информация в балуне
            },
            {
              preset: 'islands#icon',
              iconColor: '#57419D',
            }
          )
          this.map.geoObjects.add(placemark)
          lastVisit = e
        }
      })
      if (Object.keys(lastVisit).length > 0) {
        this.map.setCenter([ lastVisit.lat, lastVisit.lon, ], 12) // Устанавливаем центр и зум
      }
    },
    initMap() {
      const lat = 43.233975
      const lon = 76.948567
      this.map = new ymaps.Map('map', {
        center: [ lat, lon, ],
        zoom: 11,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-show-visits-on-map {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #00000080;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep() {
    .n-select {
      .vs__actions {
        display: none;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .n-button {
      border: none;
      border-radius: 24px;
      background-color: var(--primary) !important;
      color: #FFFFFF;
    }
  }
  .buttons {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;

    .date-place {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    #map {
      margin: 20px;
      width: 500px;
      height: 300px;
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid var(--primary);
    }

    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
