<template>
  <div class="c-importer">
    <n-loader :loading="$var('load')" />
    <div class="import-button" @click="show = true">
      <img :src="imgImport" alt="">
    </div>
    <div v-if="show" class="import-window-shadow">
      <div class="import-window">
        <div class="top">
          <div class="title">{{ titles[type] }}</div>
          <div class="patterns">
            <label class="button-place">
              <input
                class="hidden"
                accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                type="file" @change="onFileChange">
              <span class="button rotate" :class="{'success': fileId !== null}">
                <img :src="imgImport" alt="">
              </span>
              <span class="title">Загрузить шаблон</span>
            </label>
            <div class="button-place" @click="downloadPattern">
              <div class="button">
                <img :src="imgImport" alt="">
              </div>
              <div class="title">Скачать шаблон</div>
            </div>
          </div>
          <div class="progress-place">
            <div v-if="!!fileId" class="title">Не закрывайте и не перезагружайте страницу во время загрузки</div>
            <div class="title">{{ !!fileId ? 'Прогресс загрузки: '+(Math.round(importModel.progress) || 0)+' %' : 'Загрузите шаблон' }}</div>
            <c-progress-bar :fill-at="importModel.progress || 0" />
            <div class="title danger">{{ $var('err') }}</div>
          </div>
          <n-table v-if="Object.keys(importModel).length > 0" :columns="columns" :data="[importModel]"></n-table>
        </div>
        <div class="bottom">
          <n-button :disabled="!!fileId" class="tool-btn cancel" @click="close">
            Отменить
          </n-button>
          <n-button :disabled="disableFinish" class="tool-btn" @click="close">
            Завершить
          </n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgImport from '../../assets/donwloadExcel.svg'

export default {
  name: 'CImporter',
  props: {
    type: {
      type: String,
      default: 'locationPoint',
    },
  },
  data() {
    return {
      imgImport,
      show: false,
      file: null,
      url: '',
      fileId: null,
      checkStatus: null,
      user: $app.auth.user(),
      importModel: {},
      disableFinish: true,
      columns: [
        { name: 'id', title: 'Идентификатор импорта', },
        { name: 'failedCount', title: 'Неудача', },
        { name: 'successCount', title: 'Удача', },
        { name: 'itemsCount', title: 'Количество строк данных', },
      ],
      titles: {
        locationPoint: 'Импорт точек контоля',
        department: 'Импорт отделов',
        user: 'Импорт сотрудников',
      },
    }
  },
  watch: {
    importModel() {
      if (this.importModel.progress >= 100) {
        this.cleanInterval()
        this.disableFinish = false
      }
    },
  },
  beforeDestroy() {
    this.cleanInterval()
  },
  methods: {
    close() {
      this.file = null
      this.fileId = null
      this.url = null
      this.importModel = {}
      this.show = false
      this.$emit('done')
    },
    cleanInterval() {
      if (this.checkStatus) {
        clearInterval(this.checkStatus)
      }
    },
    onFileChange(e) {
      this.file = e.target.files[0]
      this.url = URL.createObjectURL(this.file)
      this.saveFile()
    },
    check() {
      $api.companies.checkUploadProgress(this.user.companyId, this.importModel.id).then((response) => {
        this.importModel = response.data.content
      })
    },
    loadPattern() {
      $api.companies.uploadPattern(this.user.companyId, this.fileId, { type: this.type, })
        .then((response) => {
          this.importModel = response.data.content
          this.checkStatus = setInterval(this.check, 2000)
        }).catch((err) => {
          this.$var('err', err.response.data.message)
        })
    },
    saveFile() {
      this.$var('load', true)
      if (this.file.length !== 0) {
        $api.files.create({ file: this.file, }).then((response) => {
          this.fileId = response.data.data.id
          this.$var('err', '')
          this.cleanInterval()
          this.loadPattern()
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    downloadPattern() {
      let url = $env('server').replace(/\/api\//g, '') +'/'
      if (this.type === 'locationPoint') {
        url = url +'Точки контроля.xlsx'
      }
      
      if (this.type === 'user') {
        if ($app.auth.user().company?.successCard === 'bazis') {
          url = url + 'Сотрудники-орг.xlsx?2'
        } else {
          url = url + 'Сотрудники.xlsx?2'
        }
      }
      if (this.type === 'department') {
        url = url + 'Отделы.xlsx'
      }
      const a = document.createElement('a')
      a.href = url
      a.click()
      a.remove()
    },
  },
}
</script>

<style lang="scss" scoped>
  .c-importer {
    .import-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .import-window-shadow {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 100000;
      display: flex;
      align-items: center;
      justify-content: center;
      .import-window {
        padding: 15px;
        min-width: 800px;
        min-height: 400px;
        background-color: #FFFFFF;
        border-radius: 16px;
        border: 2px solid var(--primary);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .tool-btn {
          color: #FFFFFF;
          background-color: var(--primary);
          border: 3px solid var(--primary);
          border-radius: 24px;
          &.cancel {
            color: #000000;
            background-color: #D4CFCF;
            border: 3px solid #D4CFCF;
          }
        }
      }
      .title {
        text-align: center;
        font-size: 16px;
        &.danger {
          color: red;
        }
      }
      .patterns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-size: 14px;
        }
        .button-place {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .button {
            &.success {
              background-color: green;
            }
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary);

            &.rotate {
              transform: rotate(180deg);
            }
          }
        }
      }
      .progress-place {
        margin-top: 15px;
        .title {
          font-size: 14px;
          text-align: left;
        }
        ::v-deep {
          .progress-bar {
            .main-empty {
              height: 40px;
            }
            .fill {
              height: 40px;
            }
          }
        }
      }
    }
  }
</style>
