<template>
  <div class="c-exporter">
    <n-loader :loading="$var('load')" />
    <div class="export-button" @click="show = true">
      <img :src="imgImport" alt="">
    </div>
    <div v-if="show" class="export-window-shadow">
      <div class="export-window">
        <div class="close" @click="close">
          <div class="circle">X</div>
        </div>
        <div class="text title">Экспорт</div>
        <div class="text">По нажатию на кнопку "экспортировать", файл будет доступен на странице экспортов</div>
        <div class="buttons">
          <n-button class="tool-btn" @click="makeExport">
            Экспортировать
          </n-button>
          <n-button class="tool-btn cancel" @click="$router.push({name: 'exports'})">
            Обзор экспортов
          </n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgImport from '../../assets/donwloadExcel.svg'

export default {
  name: 'CExporter',
  props: {
    type: {
      type: String,
      default: 'sheet',
    },
    data: {
      type: Object,
      required: true,
    },
    stats: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      imgImport,
      user: $app.auth.user(),
      show: false,
    }
  },
  methods: {
    makeExport() {
      this.$var('load', true)
      $api.companies.export(this.user.companyId, {
        ...this.data,
        type: this.type,
        stats: this.stats,
      }).then(() => {
        this.$router.push({ name: 'exports', })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    close() {
      this.show = false
      this.$emit('done')
    },
  },
}
</script>

<style lang="scss" scoped>
  .c-exporter {
    .export-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .tool-btn {
      color: #FFFFFF;
      background-color: var(--primary);
      border: 3px solid var(--primary);
      border-radius: 24px;
      &.cancel {
        color: #000000;
        background-color: #D4CFCF;
        border: 3px solid #D4CFCF;
      }
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: right;
      .circle {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .export-window-shadow {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 100000;
      display: flex;
      align-items: center;
      justify-content: center;
      .export-window {
        padding: 15px;
        min-width: 400px;
        background-color: #FFFFFF;
        border-radius: 16px;
        border: 2px solid var(--primary);
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button {
          cursor: pointer;
          height: 40px;
          color: #FFFFFF;
          background-color: var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          border-radius: 18px;
          &.goto {
            background-color: #EBEBEB;
            color: #000000;
          }

        }
      }
      .text {
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
       &.title {
         font-size: 16px;
       }
      }
    }
  }
</style>
