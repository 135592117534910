<template>
  <div class="date-picker">
    <div v-if="showBody" class="background-clicker" @click="showBody = false" />
    <div class="calendar" @click.stop>
      <div v-if="danger.length > 0" class="danger-message">
        {{ danger }}
      </div>
      <div class="calendar-head" :class="{'body-open': showBody, 'danger': danger.length > 0}" @click="!text ? showBody = true : ''">
        <div v-if="showBody" class="left-arrow" @click.stop="decrease">
          <n-icon icon="arrow-left" />
        </div>
        <div class="title">
          {{ date ? $app.date.format(date, 'day', true) : 'Дата не выбрана' }}.
          <n-select small :text="!showBody" :data="selectMonths" :value.sync="selectedMonth" />.
          <n-select small :text="!showBody" :data="selectYears" :value.sync="selectedYear" />
        </div>
        <div v-if="showBody" class="right-arrow" @click.stop="increase">
          <n-icon icon="arrow-right" />
        </div>
      </div>
      <div v-show="showBody" class="calendar-body">
        <div class="month-title">{{ months[cDate.getMonth()] }}</div>
        <div class="days">
          <div v-for="(weekDay, index) in defDays" :key="index" class="wrap-day">
            <div class="day">{{ weekDay }}</div>
          </div>
        </div>
        <div class="dates">
          <div v-for="(week, index) in calendar()" :key="index" class="date-row">
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[1]?.numY === getNumberOfDay(cDate)}" @click="select(week[1]?.date)">
                {{ week[1]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[2]?.numY === getNumberOfDay(cDate)}" @click="select(week[2]?.date)">
                {{ week[2]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[3]?.numY === getNumberOfDay(cDate)}" @click="select(week[3]?.date)">
                {{ week[3]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[4]?.numY === getNumberOfDay(cDate)}" @click="select(week[4]?.date)">
                {{ week[4]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[5]?.numY === getNumberOfDay(cDate)}" @click="select(week[5]?.date)">
                {{ week[5]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[6]?.numY === getNumberOfDay(cDate)}" @click="select(week[6]?.date)">
                {{ week[6]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[0]?.numY === getNumberOfDay(cDate)}" @click="select(week[0]?.date)">
                {{ week[0]?.index }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CDatePicker',
  props: {
    danger: {
      type: String,
      default: '',
    },
    text: {
      type: Boolean,
      default: false,
    },
    preDate: {
      type: Date,
      default: () => {
        return new Date()
      },
    },
  },
  data() {
    return {
      year: null,
      days: [],
      defDays: [],
      rWeeks: [],
      rDays: [],
      months: [],
      showBody: false,
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
      selectMonths: [
        { value: 0, title: 'Январь', },
        { value: 1, title: 'Февраль', },
        { value: 2, title: 'Март', },
        { value: 3, title: 'Апрель', },
        { value: 4, title: 'Май', },
        { value: 5, title: 'Июнь', },
        { value: 6, title: 'Июль', },
        { value: 7, title: 'Август', },
        { value: 8, title: 'Сентябрь', },
        { value: 9, title: 'Октябрь', },
        { value: 10, title: 'Ноябрь', },
        { value: 11, title: 'Декабрь', },
      ],
      selectYears: [
        { value: 2010, title: '2010', },
        { value: 2011, title: '2011', },
        { value: 2012, title: '2012', },
        { value: 2013, title: '2013', },
        { value: 2014, title: '2014', },
        { value: 2015, title: '2015', },
        { value: 2016, title: '2016', },
        { value: 2017, title: '2017', },
        { value: 2018, title: '2018', },
        { value: 2019, title: '2019', },
        { value: 2020, title: '2020', },
        { value: 2021, title: '2021', },
        { value: 2022, title: '2022', },
        { value: 2023, title: '2023', },
        { value: 2024, title: '2024', },
        { value: 2025, title: '2025', },
        { value: 2026, title: '2026', },
        { value: 2027, title: '2027', },
        { value: 2028, title: '2028', },
        { value: 2029, title: '2029', },
        { value: 2030, title: '2030', },
        { value: 2031, title: '2031', },
        { value: 2032, title: '2032', },
        { value: 2033, title: '2033', },
        { value: 2034, title: '2034', },
        { value: 2035, title: '2035', },
        { value: 2036, title: '2036', },
        { value: 2037, title: '2037', },
        { value: 2038, title: '2038', },
        { value: 2039, title: '2039', },
        { value: 2040, title: '2040', },
        { value: 2041, title: '2041', },
        { value: 2042, title: '2042', },
        { value: 2043, title: '2043', },
        { value: 2044, title: '2044', },
        { value: 2045, title: '2045', },
        { value: 2046, title: '2046', },
        { value: 2047, title: '2047', },
        { value: 2048, title: '2048', },
        { value: 2049, title: '2049', },
        { value: 2050, title: '2050', },
      ],
      selectedMonth: {},
      selectedYear: {},
      date: this.preDate,
      cDate: this.preDate,
    }
  },
  watch: {
    selectedMonth() {
      const month = this.selectedMonth.value <= 9 ? '0'+this.selectedMonth.value : this.selectedMonth.value
      this.date = new Date(new Date(this.cDate.getFullYear(), month, this.cDate.getDate()))
      this.cDate = this.date
    },
    selectedYear() {
      this.date = new Date(new Date(this.selectedYear.value, this.cDate.getMonth(), this.cDate.getDate()))
      this.cDate = this.date
    },
    date() {
      this.$emit('update:date', this.date)
      this.$emit('update:value', this.date)
      this.$emit('change', this.date)
    },
  },
  created() {
    this.init()
    this.$emit('update:date', this.date)
    this.$emit('update:value', this.date)
  },
  methods: {
    getColor(value) {
      const checkCount = value?.checks.length
      if (checkCount >= value?.countToCheck) {
        return '#64C038'
      }
      if (checkCount < value?.countToCheck && checkCount > 0) {
        return '#A70F61'
      }

      if (checkCount <= 0) {
        return '#FF0000'
      }
      return '#ffffff'
    },
    init() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.defDays = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.selectedMonth = $n.filter(this.selectMonths, (e) => {
        if (e.value === this.month) {
          return e
        }
      })[0]
      this.selectedYear = $n.filter(this.selectYears, (e) => {
        if (e.value === this.year) {
          return e
        }
      })[0]
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    calendar() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.cDate.getFullYear(), this.cDate.getMonth() + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        days = {
          ...days,
          [new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i).getDay()]: {
            index: i,
            numY: Number(this.getNumberOfDay(new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i))),
            date: new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i),
          },
        }
        if (new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week += 1
          days = {}
        }
      }
      if (Object.keys(days).length !== 0) {
        weeks = {
          ...weeks,
          [week]: days,
        }
      }

      return weeks
    },
    select(date) {
      this.cDate = date
      this.date = this.cDate
      this.showBody = false
    },
    decrease() {
      const date = new Date(this.cDate.setMonth(this.cDate.getMonth() - 1))

      this.selectedMonth = $n.filter(this.selectMonths, (e) => {
        if (e.value === date.getMonth()) {
          return e
        }
      })[0]
      this.selectedYear = $n.filter(this.selectYears, (e) => {
        if (e.value === date.getFullYear()) {
          return e
        }
      })[0]
      this.cDate = date
    },
    increase() {
      const date = new Date(this.cDate.setMonth(this.cDate.getMonth() + 1))

      this.selectedMonth = $n.filter(this.selectMonths, (e) => {
        if (e.value === date.getMonth()) {
          return e
        }
      })[0]
      this.selectedYear = $n.filter(this.selectYears, (e) => {
        if (e.value === date.getFullYear()) {
          return e
        }
      })[0]
      this.cDate = date
    },
  },
}
</script>

<style lang="scss" scoped>
  .date-picker {

    .danger-message {
      color: red;
    }
    .background-clicker {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .calendar {
      position: relative;
      z-index: 10;
      &-head {
        border: 2px solid var(--primary);
        //background: var(--primary);
        min-width: 280px;
        height: 40px;
        display: flex;
        justify-content: center;
        padding: 0 30px;
        align-items: center;
        &.danger {
          border: 2px solid red;
        }
        border-radius: 10px;
        .n-icon {
          padding: 0 10px;
          color: #000000;
        }
        &.body-open {
          justify-content: space-between;
          border-radius: 10px 10px 0 0;
        }

        .title {
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          margin: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          :deep() {
            .n-select {
              cursor: pointer;
              .vs__dropdown-toggle {
                border: none;
              }
              .vs__dropdown-option {
                padding: 5px;
              }
              .vs__selected {
                margin: 4px 0 0;
                padding: 0;
              }
              .vs__dropdown-menu {
                &::-webkit-scrollbar {
                  visibility: hidden;
                }
              }
              .vs.vs__selected-options {
                padding: 0;
              }
              .vs__search {
                width: 1px;
                height: 0;
                padding: 0;
              }
              .text-place {
                padding: 0;
              }
            }
          }
        }
      }

      &-body {
        z-index: 100;
        border-radius: 0 0 8px 8px;
        border-left: 2px solid var(--primary);
        border-right: 2px solid var(--primary);
        border-bottom: 2px solid var(--primary);
        position: absolute;
        left: 0;
        right: 0;
        padding: 5px 0;
        background: #FFF;

        .month-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #000000;
          font-weight: 500;
        }

        .days {
          display: flex;
          justify-content: space-between;
          padding: 0 5px;

          .wrap-day {
            padding: 0 4px;
          }

          .day {
            font-weight: 700;
            font-size: 12px;
            color: #000;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.selected {
              background-color: #2C117C1A;
            }
          }
        }

        .dates {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0 7px;

          .date-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          .date-wrap {
            padding: 5px 0;

            .date {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
              font-size: 12px;
              color: #000;

              &.selected {
                background-color: #2C117C1A;
              }
              &.inactive {
                color: #777676;
              }
            }
          }
        }
      }
    }
  }
</style>
