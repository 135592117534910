<template>
  <div class="c-modal-message">
    <div class="message">
      <div class="message-container">
        <div class="text">
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
        </div>
        <div class="buttons">
          <n-button flat class="modal-button cancel" @click="disagree">Нет</n-button>
          <n-button flat class="modal-button agree" @click="agree">Да</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CModalMessage',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    agree() {
      this.$emit('update:agree', true)
      this.$emit('agree')
      this.close()
    },
    disagree() {
      this.$emit('cancel')
      this.close()
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style scoped lang="scss">
.c-modal-message {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1020;
  background: rgba(0, 0, 0, .2);
  align-items: center;
  justify-content: center;
  .message {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 8px;
    max-width: 335px;
    padding: 15px;
    .message-container {
      .text {
        h3 {
          margin: 0 0 4px 0;
          padding: 0;
          font-size: 16px;
          color: #000000;
          text-align: center;
        }
        p {
          text-align: center;
          margin: 12px 0 12px 0;
          padding: 0;
          font-size: 14px;
          color: #6D6F7B;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .n-button {
          width: 75px;
          border: none;
          border-radius: 24px;
          background-color: var(--primary) !important;
          color: #FFFFFF;
          &.cancel {
            color: #000000;
            background-color: #D4CFCF !important;
          }
        }
      }
    }
  }
}
.close-modal {
  display: none;
}
</style>
